import { useCallback, useEffect, useState } from 'react';
import { apiResponse, getUserDetailsFromOktaToken } from '../utility/commonMethods';

export function useUserAppInfo() {
  const [userAppInfo, setUserAppInfo] = useState<any>(null);
  const userInfo = getUserDetailsFromOktaToken();
  const getUserDetails = useCallback(async () => {
    const payload = {
      login_name: userInfo.email.split('@')[0],
    };
    try {
      const res = await apiResponse('post', 'get-user-access-details', [], payload);
      if (res?.data.data) {
        let pageFeature = res.data.data.page_tile_details.feature || [];
        setUserAppInfo({
          userDetails: res.data.data.user_details[0],
          pageFeature: pageFeature,
        });
        sessionStorage.setItem(
          'user-app-info',
          JSON.stringify({
            userDetails: res.data.data.user_details[0],
            pageFeature: pageFeature,
          }),
        );
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }, [userInfo]);

  useEffect(() => {
    let userAppObj: string = sessionStorage.getItem('user-app-info') || '';
    if (userAppObj !== '' && JSON.parse(userAppObj) !== null) {
      setUserAppInfo(JSON.parse(userAppObj));
    } else if (userAppInfo === null) {
      getUserDetails();
    }
  }, []);

  return userAppInfo;
}

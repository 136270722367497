import React, { useEffect, useState } from 'react';
import './index.scss';
import { Loader } from '@gitlab-rtsensing/component-library';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
import { apiResponse, getUserDetailsFromOktaToken } from '../../utility/commonMethods';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUserAppInfo } from '../../hooks/use-user-app-info';
import { RequestAccessSubmitted } from '../../components/access-request-pages/access-request-submitted';

let styleElements: Element[] | null = null;

const RequestAccessContainer: React.FC = () => {
  const page = [
    { name: 'Home', isChecked: false },
    { name: 'Brand', isChecked: false },
    { name: 'People', isChecked: false },
    { name: 'Pipeline', isChecked: false },
    { name: 'Supply', isChecked: false },
    { name: 'Manufacturing', isChecked: false },
    { name: 'Finance', isChecked: false },
    { name: 'Prioritized Agenda', isChecked: false },
    { name: 'External', isChecked: false },
  ];
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [Comments, setComments] = useState('');
  const [checkedFeatures, setCheckedFeatures] = useState(page);
  const [accessRequestSuccessful, setAccessRequestSuccessful] = useState(false);

  const userInfo = getUserDetailsFromOktaToken();

  const userAppInfo = useUserAppInfo();

  const checkHandler = (e: any) => {
    const newCheckboxes = [...checkedFeatures];
    newCheckboxes.map((page: any) => {
      if (page.name === e) {
        page.isChecked = !page.isChecked;
        setCheckedFeatures(newCheckboxes);
      }
    });
  };

  useEffect(() => {
    // Add CSS styles to the page before React app is loaded if instance exists (i.e. second load)
    if (styleElements) {
      document.head.append(...styleElements);
    }

    return () => {
      // Keep references to added style tags for the app so they can be remounted again
      if (!styleElements) {
        styleElements = [...(document.getElementsByClassName('amgen-rtsensing-admin') as unknown as Element[])];
      }

      // Remove styles once the app has unmounted and styles are preserved
      if (styleElements) {
        for (let i = 0; i < styleElements.length; i++) {
          styleElements[i].remove();
        }
      }
    };
  }, []);

  useEffect(() => {
    const pageList = checkedFeatures.filter((page: any) => page.isChecked);
    if (pageList.length > 0) {
      setDisabled(false);
    } else setDisabled(true);
  }, [checkedFeatures]);

  const submitAccessRequest = async () => {
    const accessList: object[] = [];

    //for updating page_tile_list in payload
    checkedFeatures.map((page: any) => {
      if (page.isChecked) {
        accessList.push({
          tile_name: '',
          page_name: page.name,
        });
      }
    });

    const payload = {
      page_tile_list: accessList,
      user_id: userInfo?.email.split('@')[0],
      justification: Comments, //Remove After confirmation about Access form
    };
    setLoading(true);
    try {
      const res = await apiResponse('post', 'submit-access-request', [], payload);
      if (res?.data.data) {
        window.postMessage({ action: 'access request successful' }, window.location.origin);
        setLoading(false);
        setCheckedFeatures(page);
        setAccessRequestSuccessful(true);
      } else {
        window.postMessage({ action: 'access request failed' }, window.location.origin);
        setLoading(false);
        setCheckedFeatures(page);
        toast.error('Failed to Submit Request!!');
      }
    } catch (error) {
      toast.error('Something Went Wrong!!');
      setLoading(false);

      console.log(error);
    }
  };

  return (
    <div className="request-access-form-wrapper">
      {page.length > 0 &&
        userAppInfo !== null &&
        (isLoading ? (
          <div className="adm-loader-container">
            <Loader />
          </div>
        ) : !isLoading && accessRequestSuccessful ? (
          <RequestAccessSubmitted />
        ) : (
          <form className="request-access">
            <div className="request-access__user-info">
              <div className="request-access__row-field">
                <label htmlFor="user-name" className="request-access__label ops-fs-5 ops-fw-bold">
                  Username
                </label>
                <input type="text" className="request-access__input ops-fs-5" value={userAppInfo?.userDetails.Full_Name} disabled></input>
              </div>
              <div className="request-access__row-field">
                <label htmlFor="Amgen Email" className="request-access__label ops-fs-5 ops-fw-bold">
                  Amgen Email
                </label>
                <input type="text" className="request-access__input ops-fs-5" value={userAppInfo?.userDetails.Email} disabled></input>
              </div>
            </div>
            <div className="request-access__user-info">
              <div className="request-access__row-field">
                <label className="request-access__label ops-fs-5 ops-fw-bold">Business Function</label>
                <input type="text" className="request-access__input ops-fs-5" value={userAppInfo?.userDetails.Function} disabled></input>
              </div>
              <div className="request-access__row-field">
                <label className="request-access__label ops-fs-5 ops-fw-bold">Role</label>
                <input type="text" className="request-access__input ops-fs-5" value={userAppInfo?.userDetails.Role} disabled></input>
              </div>
            </div>
            <hr></hr>
            <h6 className="request-access__h4 ops-fs-5 ops-fw-bold">
              Page Access
              <sup className="sup-star">*</sup>
            </h6>
            <span className="request-access__sub-heading ops-fs-5">The domains mentioned below encompass all pages within Sensing</span>
            <div className="feature-container">
              {checkedFeatures.map((option: any) => {
                return (
                  <div key={option.name}>
                    <span className="checkbox-option">
                      <input
                        type={'checkbox'}
                        key={option.name}
                        id={option.name}
                        value={option.name}
                        checked={option.isChecked}
                        onChange={() => checkHandler(option.name)}
                        className="checkbox-field"
                      ></input>
                      <label className="checkbox-label  ops-fs-5" htmlFor={option.name}>
                        {option.name}
                      </label>
                    </span>
                  </div>
                );
              })}
            </div>
            <div>
              <label htmlFor="Comments" className="request-access__label ops-fs-5 ops-fw-bold">
                Comments
              </label>
              <textarea
                placeholder=""
                id="Comments"
                className="request-access__textarea ops-fs-5"
                value={Comments}
                onChange={e => setComments(e.target.value)}
              ></textarea>
            </div>
            <Button type="secondary" className="request-access__button" onClick={submitAccessRequest} disabled={isDisabled} text="submit" />
          </form>
        ))}
      <ToastContainer
        className="toast-position"
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default RequestAccessContainer;

interface ApiMethods {
  [key: string]: string;
}

export const methods: ApiMethods = {
  'get-user-access-details': 'accessRequest/getAccessRequest',
  'submit-access-request': 'accessRequest/submitAccessRequest',
  'get-access-request-list': 'accessRequest/getRequestDetail',
  'submit-requests': 'accessRequest/approvalRejectRequestBulk',
  'get-ad-group-list': 'accessRequest/allowedAd',
  'filter-feature-owner-data': 'accessRequest/featureAccesslevel',
  'get-usage-metric-data': 'userMetric/getUserMetric',
  'get-user-group': 'userMetric/getUserGroup',
  'get-newsletter': 'userMetric/getNewsLettersTotalView',
  'post-newsletter': 'userMetric/submitNewsLetterView',
  'get-release-notes-statuses': 'release-notes/getReleaseNotesStatus',
  'get-release-versions': 'release-notes/getReleaseVersion',
  'get-release-workstreams': 'release-notes/getReleaseNoteWorkstreams',
  'get-release-notes': 'release-notes/getReleaseNotes',
  'create-release-notes': 'release-notes/createReleaseNotes',
  'create-release-version': 'release-notes/createReleaseVersion',
  'update-release-notes': 'release-notes/updateReleaseNotes',
  'update-release-note-status': 'release-notes/updateReleaseNoteStatusId',
  'update-release-version': 'release-notes/updateReleaseVersion',
  'get-user-audit-workstream': 'userAudit/getWorkstreamADGroup',
  'get-ad-group-user-list': 'userAudit/getADGroupUserList',
  'remove-user': 'userAudit/removeUserFromAD',
  'get-user-info': 'userAudit/getUserADList',
  'get-all-adgroup': 'userAudit/allADList',
  'get-add-user-logs': 'userAudit/addAdUserLog',
  'get-remove-user-logs': 'userAudit/removeAdUserLog',
  'get-adgrp-acc-to-page': 'userAudit/workStreamWithADList',
  'get-description-info': 'userAudit/getADGroupDescription',
  'update-description-info': 'userAudit/updateADGroupDescription',
  'remove-user-all-adgroup': 'userAudit/removeUserFromRTSensing',
  'update-component-description': 'userAudit/updateDescription',
  'get-etl-jobs': 'etlDatabricks/getETLDatabricksJobStatus',
  'get-metric-name': 'dataInventory/getMetricName',
  'get-business-attributes-name': 'dataInventory/getBusinessAttributeName',
  'get-data-inventory-details': 'dataInventory/getInventoryData',
  'get-config-api-ws-list': 'sys-config/getWorkstreamDetails',
  'get-config-api-category-list': 'sys-config/getConfigCategoryDetails?WORKSTREAM_ID=',
  'get-config-api-data': 'sys-config/getConfigKeyValueDetails?WORKSTREAM_ID=',
  'update-config-api-data': 'sys-config/updateConfigDetails',
  'get-usage-metric-names': 'sys-config/getConfigByWorkStream',
  'insert-config-api-data': 'sys-config/insertConfigDetails',
  'quick-add-user': 'userAudit/addRemoveUserFromAD',
  'get-brand-data': 'brandRefresh/getBrandRefreshData',
  'update-brand-data': 'brandRefresh/updateBrandRefreshData',
  'submit-external-access-request': 'accessRequest/ExternalSubmitAccessRequest',
  'get-external-user-logs': 'accessRequest/getExternalAccessRequest',
  'get-sensing-gpt-user-feedback': 'sensingAdminGPT/getSensingGPT?from_date=',
  'get-sensing-gpt-user-feedback-details': 'sensingAdminGPT/getSensingGPTActivity?from_date=',
  'get-people-mapping': 'sensingAdminPeopleMapping/getAdminPeopleMapping?page=',
  'update-add-people-mapping': 'sensingAdminPeopleMapping/updatePeopleMapping',
  'update-remove-people-mapping': 'sensingAdminPeopleMapping/deletePeopleMapping',
  'submit-feedback-action': 'sensingAdminGPT/updateGPTAction',
  'get-vp-mapping-difference': 'sensingAdminPeopleMapping/getPeopleMappingFileVpDiff',
  'post-people-mapping': 'sensingAdminPeopleMapping/postVPMappingPublish?env_name=',
};

export const getApiUrl = (url: string) => {
  const method = methods[url];
  let finalURL = process.env.REACT_APP_API_LINK;
  return `${finalURL}\\${method}`;
};

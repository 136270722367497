import './index.scss';

interface requestMsgProps {
  IsMessage?: boolean;
  Message?: string;
}

export function RequestAccessSubmitted(props: requestMsgProps) {
  const { IsMessage, Message } = props;
  return (
    <div className="request-submitted-container">
      <div className="request-submitted-inner-content">
        <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 24 24" fill="none" className="request-submitted-check-svg">
          <path
            d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
            stroke="#75A8DD"
            stroke-width="2"
          />
          <path
            d="M9 12L10.6828 13.6828V13.6828C10.858 13.858 11.142 13.858 11.3172 13.6828V13.6828L15 10"
            stroke="#75A8DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {!IsMessage ? (
          <div>
            <h2 className="request-submitted-title ops-fs-2 ops-lh-2 ops-fw-bold">Access Request Submitted</h2>
            <p className="request-submitted-message ops-fs-3 ops-lh-3">
              Thank you for submitting your request form. We have received it and it is currently pending review. Our team will get back to you as soon as possible.
              Thank you for using Sensing.
            </p>
          </div>) :
          (<h2 className="request-submitted-title ops-fs-2 ops-lh-2 ops-fw-bold">
            {Message}
          </h2>)
        }
      </div>
    </div>
  );
}
